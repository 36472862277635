<template>
  <div id="container" v-if="fetch_data">

    <section id="mypage">
      <div class="wrap">
        <!-- 複眼経済塾からの休塾・退塾について -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>複眼経済塾からの休塾・退塾について</span></h2>
          <p class="lead_txt">
            こちらのページでは複眼経済塾からの休塾ならびに退塾のお手続きを行います。<br>
            塾生クラスの変更（アップグレード・ダウングレード）をご希望の方は下のボタンから「塾生クラスの変更」画面へ移動してください。
          </p>

          <div class="button_wrap mt0">
            <router-link :to="{name: 'UsersCustomerRankChange'}" class="submit light skeleton">塾生クラスの変更</router-link>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">休塾について</h3>
          <p class="lead_txt">
            さまざまな事情でしばらく複眼経済塾を利用する予定がない場合、「休塾」することで会員情報とポイントを維持した状態で複眼経済塾の利用を停止することが出来ます。休塾の期間は最大12ヶ月間とし、12ヶ月を超過した場合は自動的に退塾処理が行われます。<br>
            2回休塾した方は休塾はできません。<br>
            なお休塾開始時に保有しているポイントはそれぞれの失効予定日通りに失効となります。
          </p>
          <p class="lead_txt" v-if="isRegularDefault()">
            現在は【2022年4月以前入塾者特別割引価格】にて継続をいただいておりますが、一度退塾をされますとこちらの特典が継続できなくなります。<br>
            再度ご入塾いただく際は、正規料金でのご案内となります。
          </p>
          <p class="lead_txt">
            また、会社四季報ONLINEの「複眼経済塾生特別割引」の割引特典は、休塾と共に消滅し、ご利用のIDは自動停止となります。<br>
            四季報ONLINEの利用をご希望の場合は、直接、東洋経済新報社様に改めてお申込み頂く必要があります。<br>
            復塾の際、再度四季報ONLINEをご利用の場合もIDは引き継ぐことができません。
          </p>
          <div class="bdr_box kyujyuku">
            <div class="flex">
              <h4 class="blue bold">休塾</h4>
              <div class="contents">
                現在の会員情報、獲得ポイント、所有しているクーポンを維持したまま、サービスの利用を停止します。<br class="sp"><br class="sp">休塾中はマイページ以外へのアクセスはできません。複眼経済塾の塾生専用コンテンツの利用はできません。<br
                  class="sp"><br class="sp">休塾中の月謝は必要ありませんが、塾生資格維持のための費用が発生します。<br><br class="sp">
                <span class="red bold">休塾の期間は最大12ヶ月間とし、それを超過した場合は自動的に退塾となります。</span>
              </div>
              <div class="price">
                <h5 class="bold">塾生資格維持費</h5>
                <p class="blue">¥ 1,000</p>
              </div>
            </div>
          </div>

          <div class="button_wrap">
            <a href="javascript:void(0)" class="submit arrow large light"
               v-bind:class="{disabled: current_user.sleep_request}" @click="membershipSleep">複眼経済塾を休塾する</a>
          </div>

          <div class="button_wrap" v-if="current_user.sleep_request">
            <a href="javascript:void(0)" class="submit arrow large light" @click="cancelMembershipSleep">休塾申請をキャンセル</a>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">現在のポイント残高</h3>
          <p class="lead_txt">
            現在のポイント残高は以下の通りです。退会した場合こちらのポイントは全額失効します。
          </p>
          <div class="bdr_box point_balance">
            <div class="flex">
              <h4>現在のポイント残高</h4>
              <p><span>{{ delimited(fetch_data.point.total_points) }}</span>ポイント</p>
            </div>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">退塾について</h3>
          <p class="lead_txt">
            複眼経済塾の利用を終了します。
          </p>
          <div class="bdr_box taikai">
            <div class="flex">
              <h4 class="red bold">退塾</h4>
              <div class="contents red">
                月謝の月額払いを選択している場合は退塾を申請した月の末日、年一括払いを選択している場合は現在お支払いいただいている月謝の充当期間最終月の末日で正式に退塾となります。<br class="sp"><br
                  class="sp">退塾日以降の複眼経済塾へのログインはできません。退塾した時点ですべての塾生情報は無効となり、獲得したポイント、所有クーポンはすべて失効します。獲得済みポイントの換金や購入済みコンテンツの返金処理は出来ません。
              </div>
            </div>
          </div>

          <div class="button_wrap">
            <a href="javascript:void(0)" @click="membershipWithdrawal()"
                         v-bind:class="{disabled: current_user.withdrawal_request}" class="submit arrow large light">
              複眼経済塾を退塾する
            </a>
            <p class="sp_alnC">アンケートへのご協力をお願いいたします。</p>

            <div class="button_wrap" v-if="current_user.withdrawal_request">
              <a href="javascript:void(0)" class="submit arrow large light" @click="cancelMembershipWithdrawal">退塾申請をキャンセル</a>
            </div>
          </div>
          <div class="button_wrap">
            <p class="alnC sp_alnL">休塾・退塾を希望されない方はこちらのボタンからマイページにお戻りください。</p>
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="submit arrow back large light skeleton">マイページに戻る
            </router-link>
          </div>
        </div>

      </div>
    </section>

    <div class="modal" v-bind:class="{open: modal_open}">
      <div class="modal_box">
        <div class="header">
          <h4>申し込み済みの「月例会」<br class="sp">「研修会」があります。</h4>
          <div class="close modal_close" v-on:click="modal_open = null"></div>
        </div>
        <div class="inner">
          <p>
            キャンセル申請をせずに{{ modal_open }}すると返金がされません。<br>
            {{ modal_open }}日までに<router-link to="/contacts/">事務局にキャンセルのご連絡</router-link>をお願いいたします。
          </p>

          <div class="btn_wrap">
            <a href="javascript:void(0)" v-on:click="sleepWithdrawalExec()" class="submit green">了承した</a>
          </div>
          <div class="btn_wrap">
            <a href="javascript:void(0);" class="submit gray modal_close" v-on:click="modal_open = null">キャンセル</a>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null,
      any_registered_meetings: false,
      withdrawal_reason: 'withdrawal_reason_1',
      withdrawal_reason_text: null,
      modal_open: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/point.json`, {})
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.fetchData2()
          })
    },
    fetchData2() {
      this.axios
          .post(`${this.env.api_base_url}users/any_registered_meetings.json`, {})
          .then(response => {
            console.log(response.data)
            this.any_registered_meetings = response.data.result
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },

    sleepWithdrawalExec() {
      const mode = this.modal_open
      this.modal_open = null
      setTimeout(() => {
        if (mode === '休塾') {
          this.membershipSleepExec();
        } else if (mode === '退塾') {
          this.$router.push({name: 'UsersCustomerWithdrawal'})
        }
      }, 100)
    },

    membershipSleep() {
      if (this.any_registered_meetings) {
        this.modal_open = '休塾'
      } else {
        this.membershipSleepExec();
      }
    },

   membershipSleepExec() {
      if (!confirm('休塾の申請を実施しますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/membership_sleep.json`, {})
          .then(response => {
            // console.log(response.data)
            if (response.data.sleep_at) {
              const message = `休塾の申請を受け付けました。\n実際に休塾へと移行するのは、${this.moment(response.data.sleep_at, 'YYYY年MM月DD日')} となります。\nマイページへ戻ります。`
              alert(message)
            } else if (response.data.sleep_execute_count) {
              const message = `2回休塾しているので休塾の申請を受理出来ませんでした。\nマイページへ戻ります。`
              alert(message)
            } else {
              const message = `休塾の申請を受理出来ませんでした。\n事務局までお問い合わせください。\nマイページへ戻ります。`
              alert(message)
            }
            // マイページへ
            this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    },
    cancelMembershipSleep() {
      if (!confirm('休塾の申請をキャンセルしますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/cancel_membership_sleep.json`, {})
          .then(() => {
            // console.log(response.data)
            const message = `休塾の申請をキャンセルしました。\nマイページへ戻ります。`
            alert(message)
            // マイページへ
            this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    },

    membershipWithdrawal() {
      if (this.any_registered_meetings) {
        this.modal_open = '退塾'
      } else {
        this.$router.push({ name: 'UsersCustomerWithdrawal'})
      }
    },

    cancelMembershipWithdrawal() {
      if (!confirm('退塾の申請をキャンセルしますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/cancel_membership_withdrawal.json`, {})
          .then(() => {
            // console.log(response.data)
            const message = `退塾の申請をキャンセルしました。\nマイページへ戻ります。`
            alert(message)
            // マイページへ
            this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>
